.dragable_top_section_box_minute_text {
    color: #101214;
    font-size: 10px;
    text-align: center;
    margin: auto;
    z-index: 99 !important ;

}


.tooltip-adjust{
    color: "white";
    padding: "2px 0px";
    z-index: -1
}

.mt_5 {
    margin-top: 5px;
}

.main_tooltip_section {
    display: flex;
    justify-content: space-between;
    width: 950px;
    
}
.main_tooltip_section2 {

    display: flex;
    justify-content: space-between;
    width: 1000px;

}
.main_tooltip_section1 {
    display: flex;
    justify-content: space-between;
    width: 1320px;
    
}
.pt_20 {
    padding-top: 15px;
}

.trailer_gap {
    padding-top: 20px;
}
.trailer_gap1 {
    padding-top: 40px;
}

.driver_special_text {
    text-decoration-line: underline;
    text-decoration-style: double;
    color: "white";
    font-family: "Manrope-Bold";
    padding-right: 5px;
    font-size: 15px;

}
.w-100 {
    width: 100%;
}
.Adress_break {
    width: 60%;
    word-wrap: break-word;
}
.pt_55 {
    padding-top: 20px;
}

.pt_12 {
    padding-top: 13px;
}

.pt_10 {
    padding-top: 2px;
}

.address_set {
    height: 60px;
    
}

.tooltip_inner_gap {
    padding-top: 15px;

}

.tooltip_inner_gap1 {
    padding-top: 10px;

}

.tooltip_left {
    padding-right: 20px;
    width: 39%;
}

.tooltip_left_1 {
    padding-right: 20px;
    width: 30%;
}

.tooltip_left_2 {
    padding-right: 20px;
    width: 20%;
}
.tooltip_left_3 {
    padding-right: 20px;
    width: 30%;
}
.tooltip_left_4 {
    padding-right: 20px;
    width: 20%;
}

.tooltip_left_driver {

    padding-right: 0px;
    width: 40%;

}

.tooltip_middle {
    padding-left: 20px;
    width: 24%;
}

.tooltip_middle_driver {
    padding-left: 0px;
    width: 20%;
}

.tooltip_right {
    padding-left: 20px;
    width: 37%;
}
.tooltip_rightPu {
    padding-left: 20px;
    width: 23%;
}

.tooltip_right_driver {
    padding-left: 0px;
    text-align: left;
    width: 20%;
}

.tooltip_single_sec {
    display: flex;
    margin-top: 5px;
    /* align-items: center; */
    
}

.tooltip_text_left {
    color: "white";
    font-family: "Manrope-Bold";
    padding-right: 5px;
    font-size: 15px;
}

.planner_driver_icon_section {
    display: flex;
    align-items: center;
    height: 64px;
    margin-top: 18px;
    /* border: 1px solid red; */
    margin-right: 2px;
}
.driver_right_text {
    font-size: 10px;
    text-align: right;
    margin-left: 4px;
    color: black;

}
.driver_right_text p {
    margin: 0px;
}

.fs_20 {
    font-size: 20px;
}

.tooltip_text_right {
    color: "white";
    font-size: 14px;
    font-family: "Manrope-Regular";
}

.planner_tooltip_section {
    display: flex;
}
.blockwidth_fixed{
    width:100%
}